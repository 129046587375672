<template>
	<!-- 菜单 -->
	<header id="header" class="header">
		<div class="header_top">
			<div class="w1239 relative">
				<div class="logo phonenone">
					<img v-if="sysRole=='huaxue'" src="../../assets/logo/home_logo.png">
					<img v-if="sysRole=='jiadian'" src="../../assets/logo/home_logo_jiadian.png">
					<img v-if="sysRole=='zhongshan'" src="../../assets/logo/home_logo_zhongshan.png">
					<img v-if="sysRole=='ztianhe'" src="../../assets/logo/home_logo_tianhe.png">
				</div>
				<!-- <div class="header-con relative fr">
					<div class="con_search fontsize14" @click="handleSearche('')">
						<div class="eldropdown">
							<el-dropdown trigger="click" @command="handcommand1">
							  <span class="el-dropdown-link">
							    专利
								<i class="el-icon-arrow-down el-icon--right"></i>
							  </span>
							  <el-dropdown-menu slot="dropdown">
							    <el-dropdown-item command="">分类1</el-dropdown-item>
							    <el-dropdown-item command="">分类2</el-dropdown-item>
							    <el-dropdown-item command="">分类3</el-dropdown-item>
							  </el-dropdown-menu>
							</el-dropdown>
						</div>
						<div class="input fontsize12"></div>
						<input class="input fontsize12" placeholder="" disabled/>
						<div class="seabtn"><i class="el-icon-search"></i></div>
					</div>
				</div> -->
				<div class="pvNum pcnone">访问量：{{pvNum}}</div>
				<div class="register_login loginbox" v-if="!isLogin" @click="$util.routerPath('/login')">
					<img class="img" src="../../assets/static/login_icon.png"/>
					<div class="text fontsize12">登录</div>
				</div>
				<div class="register_login registerbox" v-if="!isLogin" @click="$util.routerPath('/register')">
					<img class="img" src="../../assets/static/register_icon.png"/>
					<div class="text fontsize12">注册</div>
				</div>
				<div class="login_success_box" v-if="isLogin">
					<img class="img" src="../../assets/static/login_icon.png"/>
					<div class="textdiv fontsize12">
						<div class="text1 clamp">{{userInfo.phone}}</div>
						<div class="text2"></div>
						<div class="text3" @click="outLogin">退出</div>
					</div>
				</div>
				<div class="font_con">
					<el-dropdown trigger="click" @command="handcommand2">
					  <span>
					     字号<span class="text">{{fontsize}}</span>
					  </span>
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item command="small">小</el-dropdown-item>
					    <el-dropdown-item command="middle">中</el-dropdown-item>
					    <el-dropdown-item command="large">大</el-dropdown-item>
					  </el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="bgimg"><img src="../../assets/static/bgimg.png"></div>
			</div>
		</div>
		<!-- :style="'background:rgba(36, 127, 228,'+bgnum+');'" -->
		<div class="my-container">
			<div class="logo pcnone">
				<img v-if="sysRole=='huaxue'" src="../../assets/logo/pc_home_logo.png">
				<img v-if="sysRole=='jiadian'" src="../../assets/logo/pc_home_logo_jiadian.png">
				<img v-if="sysRole=='zhongshan'" src="../../assets/logo/pc_home_logo_zhongshan.png">
				<img v-if="sysRole=='ztianhe'" src="../../assets/logo/pc_home_logo_tianhe.png">
			</div>
			<div class="w1239">
				<div class="navbar navbar-expand-lg navbar-light">
					<!-- 导航栏中的矢量图：实例中product，右键查看源代码，复制a标签的内容，粘贴如下 -->
					<button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#collapsibleNavbar"><span class="navbar-toggler-icon"></span></button>
					<!-- ml-auto目的是使ul位置在右边 -->
					<div class="home_tab collapse navbar-collapse" id="collapsibleNavbar">
						<ul class="navbar-nav">
							<li class="nav-item" :class="curpage == item.url ? 'cur_tab' : ''" v-for="(item, index) in menulist" :key="item.index">
								<a v-if="item.shxCatalogue.length<=0" @click="menuClick(index, item.url)" class="fontsize16 nav-link">{{ item.menu }}</a>
								<a @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-if="item.shxCatalogue.length>0" @click="menuClick(index, item.url)" class="fontsize16 dropdown_a nav-link">{{ item.menu }}</a>
								<ul @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)" v-if="item.shxCatalogue.length>0" :class="isActive==index ? 'sub-menu sub-menuact' : 'sub-menu'">
								   <li v-for="(sitem, sindex) in item.shxCatalogue" :key="sindex" @click="menuSecHandle(index, sitem.redirectUrl,sitem.needAuth)" :class="cur_cli == sitem.id? 'cur_cli' : ''">
								      <a>{{sitem.title}}</a>
								   </li>
								</ul>
								<!-- <div v-if="index === 1" class="zhebzhao" @mouseenter="mouseEnter" @mouseleave="mouseLeave"></div> -->
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="pcpvNum phonenone">访问量：{{pvNum}}</div>
		</div>
	</header>
</template>
<script>
import {mapState,mapMutations} from 'vuex'
export default {
	name: 'menuBar',
	data() {
		return {
			current_index: 0, // 当前索引
			isActive: 0, //鼠标移入索引
			cur_cli: 0, //0表示没有,选择的id
			menulist: [
				{
					index: 0,
					menu: '首页',
					url: 'home',
					shxCatalogue:[],
				},
				// {
				// 	index: 1,
				// 	menu: '知识产权分析',
				// 	url: 'analysis'
				// },
				// {
				// 	index: 2,
				// 	menu: '知识产权交易',
				// 	url: 'deal'
				// },
				// {
				// 	index: 4,
				// 	menu: '知识产权法律',
				// 	url: 'law'
				// },
				// {
				// 	index: 5,
				// 	menu: '知识产权金融',
				// 	url: 'finance'
				// },
				// {
				// 	index: 6,
				// 	menu: '项目服务',
				// 	url: 'service'
				// },
				// {
				// 	index: 7,
				// 	menu: '资讯 ',
				// 	url: 'information'
				// },
				// {
				// 	index: 8,
				// 	menu: '关于我们',
				// 	url: 'mine'
				// }
			],
			bgnum:1,//透明度
			fontsize:"中",
		};
	},
	computed: {
		...mapState(['userInfo', 'isLogin','curpage','sysRole','pvNum']),
	},
	created() {
		console.log(this.curpage)
		this.getCatalogue()
		// 此处true需要加上，不加滚动事件可能绑定不成功
		//window.addEventListener("scroll", this.handleScroll, true);
		//获取浏览量数据
		this.getHxVisit()
		//增加浏览量数据
		this.addHxVisit()
	},
	methods: {
		...mapMutations(["SET_USERINFO",'SET_ISLOGIN','SET_pvNum']),
		//退出
		outLogin(){
			var _this = this
			this.$confirm("确认要退出登录嘛？", '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$message.success('退出登录成功！')
				sessionStorage.setItem('token','');
				_this.SET_ISLOGIN(false)
				_this.SET_USERINFO({})
				//保存
				sessionStorage.setItem('key_state','')
				_this.$util.routerPath('/home')
			}).catch(() => {});
		},
		//获取浏览量数据
		getHxVisit() {
			var _this = this;
			var params = {};
			this.$http.post('visit/hx-visit-info/getHxVisit', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var dataobj = res.data;
					if(dataobj){
						var newnum = parseInt(dataobj.realVisit + dataobj.sysVisit)
						_this.SET_pvNum(newnum)
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//增加浏览量数据
		addHxVisit() {
			var params = {
				visitPath:window.location.href,
				sourceType1:this.sysRole
			};
			this.$http.post('visit/hx-visit-info/addHxVisit', params).then(function(res) {
				
			})
		},
		//获取菜单
		getCatalogue() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 18,
				type:0,//首页菜单
			};
			this.$http.post('frontEnd/catalogue/getCatalogue', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					//var menulist = []
					if(records.length>0){
						records.forEach((item,index)=>{
							if(item.title){
								item["index"] = index
								item["menu"] = item.title
								item["url"] = item.redirectUrl
							}
							// if(item.state==0){
							// 	menulist.push(item)
							// }
						})
						_this.menulist = records
					}
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//分类查询
		handcommand1(type){
			
		},
		//字体大小
		handcommand2(fontsize){
			if(fontsize=="small"){
				this.fontsize = "小"
			}else if(fontsize=="middle"){
				this.fontsize = "中"
			}else{
				this.fontsize = "大"
			}
			//基数是16，
			// $(".fontsize14").css("font-size",`${fontsize}px`)
			// $("html").setAttribute("data-size","middle")
			window.document.documentElement.setAttribute("data-size",fontsize)
		},
		//滚动
		handleScroll() {
		    var scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
			if (scrolltop < 368) {
				this.bgnum = scrolltop / 368
			} else {
				this.bgnum = 1
			}
		},
		menuClick(index, url) {
			console.log('我点击了menu');
			if(url){
				this.current_index = index; // 设置点击后的下标
				this.$emit("menuClick",url)
				this.$router.push({
					name: url
				});
			}else{
				this.$message.info("此模块暂未开放")
			}
		},
		//二级跳转
		menuSecHandle(index, url,needAuth){
			//二级跳转关闭
			if(this.isActive&&this.isActive>-1){
				this.isActive = -1;
			}
			this.current_index = index; // 设置点击后的下标
			if(url){
				if(needAuth==1){
					//需要登录
					if(!this.isLogin){
						this.$router.push({
							path: "/login"
						});
						return;
					}
				}
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				this.$message.info("此模块暂未开放")
				console.log("无跳转路径")
			}
		},
		//处理参数
		parseString(url){
		    const obj = {};
		    if (url&&url.split('?').length>1) {
		      const params = url.split('?')[1].split('&');
		      params.map(item => obj[item.split("=")[0]] = item.split("=")[1]);
		    }
		    console.log(obj)
			return obj
		},
		mouseEnter(index) {
			//console.log(index)
			if(index==0){
				return;
			}
			this.isActive = index;
		},
		mouseLeave(index) {
			//console.log(index)
			this.isActive = -1;
		},
		//跳转搜索
		handleSearche(url){
			if(!this.isLogin){
				this.$router.push({
					path: "/login"
				});
				return;
			}
			if(url){
				if(url.indexOf("http://")>-1 || url.indexOf("https://")>-1){
					this.$util.windowOpen(url)
				}else{
					const newUrl = url.split('?')[0]
					const params = this.parseString(url)
					this.$router.push({
						path: "/"+newUrl,
						query:params
					});
				}
			}else{
				console.log("无跳转路径")
				this.$message.info("此模块暂未开放")
			}
		},
	}
};
</script>
<style></style>
