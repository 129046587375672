<template>
	<div class="root">
		<menuBar></menuBar>
		<router-view />
		<Footer></Footer>
		<phoneCall></phoneCall>
	</div>
</template>

<script>
import menuBar from '@/components/menuBar/menuBar.vue';
import Footer from '@/components/Footer/Footer.vue';
import phoneCall from '@/components/phoneCall/phoneCall.vue';
export default {
	name: 'index',
	components: {
		menuBar,
		Footer,
		phoneCall
	},
	created() {
		window.document.documentElement.setAttribute("data-size","middle")
	},
};
</script>

<style lang="scss" scoped>
	.root{
		// padding-top: 164px;
	}
</style>
