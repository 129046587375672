<template>
	<!-- 右边标签 -->
	<div class="yx_server_div">
		<div class="yx_server_box">
			<div class="imgobj">
				<a href="tencent://message/?uin=3029853905&Site=www.chinahuaxue.com&Menu=yes">
					<img class="img img1" src="@/assets/qq.png" />
					<img class="img img2" src="@/assets/qq_white.png" />
					<div class="text">QQ客服</div>
				</a>
			</div>
			<div class="line"></div>
			<div class="imgobj">
				<a href="tencent://message/?uin=3029853905&Site=www.chinahuaxue.com&Menu=yes">
					<img class="img img1" src="@/assets/erweima.png" />
					<img class="img img2" src="@/assets/erweima_white.png" />
					<div class="text">公众号</div>
				</a>
			</div>
			<div class="yx_service_consult">
				<span class="yx_service_triangle"></span>
				<div class="yx_service_img"></div>
				<div class="yx_title">华学公众号</div>
				<div class="yx_service_img_zc"></div>
				<div class="yx_title">华知智创公众号</div>
			</div>
		</div>
		<div class="hblock12"></div>
		<!-- <div class="yx_share_box">
			<div class="text">邀请好友获得返现</div>
		</div> -->
		<!-- <div class="hblock12"></div>
		<div class="yx_top_box" @click="hanldetoTop" v-if="gotop">
			<img width="12" height="12" src="@/assets/top_icon.png" />
			<div class="yx_service_float">
				<span class="yx_service_triangle"></span>
				<span>返回顶部</span>
			</div>
		</div>
	    <div class="yx_top_box_empty" v-if="!gotop"></div> -->
	</div>
	
</template>
<script>
export default {
	name: 'Footer',
	data() {
		return {
			gotop: false
		};
	},
	created() {
		
	},
	beforeMount() {
	   if (window) {
	      // 此处true需要加上，不加滚动事件可能绑定不成功
	      window.addEventListener("scroll", this.handleScroll, true);
	   }
	},
	methods: {
		See(e) {
			window.location.href = e;
		},
		handleScroll() {
		    var scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
		    scrolltop > 500 ? (this.gotop = true) : (this.gotop = false);
		},
		hanldetoTop(){
			var top = document.documentElement.scrollTop || document.body.scrollTop;
			// 实现滚动效果
			const timeTop = setInterval(() => {
			  document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
			  if (top <= 0) {
			    clearInterval(timeTop);
			  }
			}, 10);
		},
		
	}
};
</script>
<style scoped>
.yx_server_div {
  width: 56px;
  position: fixed;
  right: 30px;
  bottom: 288px;
  z-index: 8888;
}

.yx_server_div .hblock12 {
  width: 100%;
  height: 12px;
}

.yx_server_div .yx_server_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.yx_server_div .yx_server_box .imgobj {
  width: 56px;
  height: 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 1px 9px 0px rgba(25, 31, 40, 0.3);
}
.yx_server_div .yx_server_box .imgobj:hover {
  background-color: #247FE4;
}
.yx_server_div .yx_server_box .imgobj a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.yx_server_div .yx_server_box .imgobj a .img {
  width: 22px;
  height: 22px;
}
.yx_server_div .yx_server_box .imgobj a .img1 {
  display: block;
}
.yx_server_div .yx_server_box .imgobj a .img2 {
  display: none;
}
.yx_server_div .yx_server_box .imgobj:hover a .img1 {
  display: none;
}
.yx_server_div .yx_server_box .imgobj:hover a .img2 {
  display: block;
}
.yx_server_div .yx_server_box .imgobj a .text {
  text-align: center;
  font-size: 12px;
  text-decoration: none;
  color: #247FE4;
}
.yx_server_div .yx_server_box .imgobj:hover a .text {
  color: #FFFFFF;
}

.yx_server_div .yx_server_box .line {
  width: 100%;
  height: 10px;
}

.yx_server_div .yx_server_box .yx_service_consult {
  width: 210px;
  height: 458px;
  border-radius: 3px;
  background: #fff;
  right: 62px;
  position: absolute;
  text-align: center;
  border: 1px solid #e1e6ec;
  display: none;
  opacity: 0;
  box-shadow: 0px 1px 9px 0px rgba(25, 31, 40, 0.3);
  box-sizing: border-box;
}

.yx_server_div .yx_server_box .yx_service_consult .yx_title {
  width: 100%;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  color: #333333;
  text-align: center;
}

.yx_server_div .yx_server_box .yx_service_consult .yx_service_img {
  width: 160px;
  margin: 20px auto 0;
  height: 160px;
  background-position: center center;
  background-size: cover;
  background-image: url("../../assets/kefu.png");
}

.yx_server_div .yx_server_box .yx_service_consult .yx_service_img_zc {
  width: 150px;
  margin: 20px auto 0;
  height: 150px;
  background-position: center center;
  background-size: cover;
  background-image: url("../../assets/huaxue_zhiip.png");
}

.yx_server_div .yx_server_box:hover .yx_service_consult {
  display: block;
  opacity: 1;
  transition: linear .2s;
  animation-name: fade-in;
  animation-duration: .3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}

.yx_server_div .yx_server_box .yx_service_triangle {
  top: 100px;
  right: -11px;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 11px solid #e1e6ec;
  z-index: 1010;
}

.yx_server_div .yx_server_box .yx_service_triangle:after {
  top: -6px;
  right: 1px;
  position: absolute;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fff;
  z-index: 1000;
}

.yx_server_div .yx_share_box {
  width: 100%;
  height: 142px;
  background-image: url(../../assets/share_bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 9px 0px rgba(25, 31, 40, 0.3);
}

.yx_server_div .yx_share_box .text {
  width: 17px;
  font-size: 11px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 15px;
  text-align: center;
}

.yx_server_div .yx_top_box {
  width: 100%;
  height: 38px;
  background: #FFFFFF;
  box-shadow: 0px 1px 9px 0px rgba(25, 31, 40, 0.3);
  border-radius: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.yx_server_div .yx_top_box .yx_service_float {
  display: none;
  position: absolute;
  right: 62px;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #e1e6ec;
  white-space: nowrap;
  box-sizing: border-box;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}

@keyframes fade-in {
  0% {
    opacity: .4;
    right: 82px;
  }
  100% {
    opacity: 1;
    right: 62px;
  }
}

.yx_server_div .yx_top_box:hover .yx_service_float {
  display: block;
  opacity: 1;
  transition: linear .2s;
  animation-name: fade-in;
  animation-duration: .3s;
  animation-iteration-count: 1;
  animation-delay: 0s;
}

.yx_server_div .yx_top_box .yx_service_triangle {
  top: 12px;
  right: -11px;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 11px solid #e1e6ec;
  z-index: 1010;
}

.yx_server_div .yx_top_box .yx_service_triangle:after {
  top: -6px;
  right: 1px;
  position: absolute;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid #fff;
  z-index: 1000;
}

.yx_server_div .yx_top_box_empty {
  width: 100%;
  height: 38px;
}

</style>
