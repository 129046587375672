<template>
	<!-- 底部 -->
    <div class="index_footer">
    	<div class="foot_con1">
    		<!-- <div class="w1239 hxcompany">
				<div class="hxcompany_title fontsize14">华学分公司</div>
				<div class="hxcompany_con fontsize12">
					<a href="http://www.chinahuaxue.com" title="总公司官网" target="_blank">总公司官网</a>
					<a href="http://www.huaxuelg.com" title="广州萝岗分公司" target="_blank">广州萝岗分公司</a>
					<a href="http://www.huaxueip.cn" title="广州增城分公司" target="_blank">广州增城分公司</a>
					<a href="http://www.chinahuaxue.com/bench.asp?id=1341" title="深圳分公司" target="_blank">深圳分公司</a>
					<a href="http://www.huaxuezs.com" title="中山分公司" target="_blank">中山分公司</a>
					<a href="http://www.hxzlsb.cn" title="东莞分公司" target="_blank">东莞分公司</a>
					<a href="http://www.huaxuenh.com" title="佛山南海分公司" target="_blank">佛山南海分公司</a>
					<a href="http://www.chinahuaxue.com/bench.asp?id=880" title="惠州分公司" target="_blank">惠州分公司</a>
					<a href="http://www.chinahuaxue.com/bench.asp?id=881" title="江门分公司" target="_blank">江门分公司</a>
					<a href="http://www.chinahuaxue.com/bench.asp?id=883" title="浙江金华分公司" target="_blank">浙江金华分公司</a>
					<a href="http://www.chinahuaxue.com/bench.asp?id=884" title="广西南宁分公司" target="_blank">广西南宁分公司</a>
					<a href="http://www.chinahuaxue.com/bench.asp?id=885" title="重庆分公司" target="_blank">重庆分公司</a>
					<a href="http://www.chinahuaxue.com/bench.asp?id=1103" title="安徽合肥分公司" target="_blank">安徽合肥分公司</a>
				</div>
			</div>
			<div class="w1239 hxline"></div> -->
			<div class="w1239 hxcompany">
				<div class="hxcompany_title fontsize14">快捷链接</div>
				<div class="hxcompany_con fontsize12">
					<!-- <a href="http://www.chinahuaxue.com/" title="广州市华学知识产权代理有限公司" target="_blank">广州市华学知识产权代理有限公司</a>
					<a href="https://www.gzhxip.com/zhongshan/" title="中山市战略性产业知识产权运营转化平台" target="_blank">中山市战略性产业知识产权运营转化平台</a>
					<a href="https://www.gzhxip.com/jiadian/" title="广东省智能家电产业知识产权公共服务平台" target="_blank">广东省智能家电产业知识产权公共服务平台</a>
					<a href="https://www.gzhxip.com/ztianhe/" title="广州天河高新技术产业开发区知识产权综合服务平台" target="_blank">广州天河高新技术产业开发区知识产权综合服务平台</a>
					<a href="http://www.sipo.gov.cn" title="中国国家知识产权局" target="_blank">中国国家知识产权局</a>
					<a href="http://www.ctmo.gov.cn/" title="中国商标网" target="_blank">中国商标网</a>
					<a href="http://www.ncac.gov.cn/" title="中国国家版权局" target="_blank">中国国家版权局</a>
					<a href="http://www.gdipo.gov.cn/" title="广东省知识产权局" target="_blank">广东省知识产权局</a>
					<a href="http://www.xwcbj.gd.gov.cn" title="广东省版权局" target="_blank">广东省版权局</a>
					<a href="http://www.gzipo.gov.cn/ " title="广州市知识产权信息网" target="_blank">广州市知识产权信息网</a>
					<a href="http://www.gzpic.cn" title="专利资助网" target="_blank">专利资助网</a>
					<a href="http://www.uspto.gov/ " title="美国专利商标局" target="_blank">美国专利商标局</a>
					<a href="http://www.jpo.go.jp/" title="日本专利局" target="_blank">日本专利局</a>
					<a href="http://www.kipo.go.kr/kpo/eng/" title="韩国知识产权局" target="_blank">韩国知识产权局</a>
					<a href="http://www.ipo.gov.uk/" title="英国知识产权局" target="_blank">英国知识产权局</a>
					<a href="http://www.dpma.de/" title="德国专利商标局" target="_blank">德国专利商标局</a>
					<a href="http://www.ieepi.org/" title="法国工业产权局" target="_blank">法国工业产权局</a>
					<a href="http://www.patentoffice.nic.in/" title="印度专利、外观设计、商标及地理标志管理总局" target="_blank">印度专利、外观设计、商标及地理标志管理总局</a>
					<a href="http://www.ipaustralia.gov.au/" title="澳大利亚知识产权局" target="_blank">澳大利亚知识产权局</a>
					<a href="http://www.scut.edu.cn" title="华南理工大学" target="_blank">华南理工大学</a> -->
					<a href="https://www.cnipa.gov.cn/" title="国家知识产权局" target="_blank">国家知识产权局</a>
					<a href="https://amr.gd.gov.cn/" title="广东省市场监督管理局（知识产权局）" target="_blank">广东省市场监督管理局（知识产权局）</a>
					<a href="https://ggfw.cnipa.gov.cn" title="国家知识产权信息公共平台" target="_blank">国家知识产权信息公共平台</a>
					<a href="http://dlgl.cnipa.gov.cn/" title="专利代理管理系统" target="_blank">专利代理管理系统</a>
					<a href="https://www.patentnavi.org.cn/patentRecognition" title="国家专利导航综合服务平台" target="_blank">国家专利导航综合服务平台</a>
				</div>
			</div>
    	</div>
		<div class="foot_con2 fontsize14">
			<div class="w1239 hxinfo">
				<div class="hxinfo_box">
					<div class="info">
						<div class="text">公司地址：广州市天河区金颖路1号金颖大厦16楼</div>
						<div class="text" v-html="'联系电话：020-38550992&nbsp;&nbsp;&nbsp;&nbsp;38550875&nbsp;&nbsp;&nbsp;&nbsp;38550767&nbsp;&nbsp;&nbsp;&nbsp;邮编：510640'"></div>
					</div>
					<div class="info infoaddr2">
						<div class="text" v-html="'联系电话：020-38743199&nbsp;&nbsp;&nbsp;&nbsp;38744550&nbsp;&nbsp;&nbsp;&nbsp;85294797&nbsp;&nbsp;&nbsp;&nbsp;'"></div>
					    <div class="text"></div>
					</div>
					<div class="info_two">
						<div class="text" v-html="'电子邮箱：huaxue@chinahuaxue.com&nbsp;&nbsp;&nbsp;&nbsp;涉外邮箱：info@chinahuaxue.com&nbsp;&nbsp;&nbsp;&nbsp;客服邮箱：support@huaxueip.com'"></div>
					</div>
					<div class="info_two">
						<div class="text">版权所有©2015-{{dateyear}} 广州市华学知识产权代理有限公司</div>
						<a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备19058236号</a>
						<a href="http://dlgl.cnipa.gov.cn/txnqueryAgencyOrg.do" target="_blank">专利代理机构查询</a>
						<a href="http://www.chinahuaxue.com/htmleditor/UploadFile/2021726114238369.jpg" target="_blank">专利代理机构执业许可证</a>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
export default {
	name: 'Footer',
	data() {
		return {
			current: 0, // 当前索引
			dateyear: ''
		};
	},
	created() {
		this.dateyear = new Date().getFullYear();
	},
	methods: {
		See(e) {
			window.location.href = e;
		}
	}
};
</script>
<style type="text/css" scoped>

</style>
